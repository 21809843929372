import styled from "styled-components"
import breakpoints from "@configs/breakpoints"

export const Details = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--grid-gap-16);
  width: 100%;
  padding: var(--gap-66) 0;
  margin-bottom: var(--gap-165);

  .background {
    position: absolute;
    margin: auto;
    height: 100%;
    top: 0;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    background: var(--clr-green);
    z-index: -1;
  }

  & > div {
    grid-column: span 7;
  }

  .img {
    grid-column: 9 / -1;
    background: white;
    border-radius: 50%;
    width: 30vw;
    height: 30vw;
    max-height: 536px;
    max-width: 536px;
    align-self: center;
    justify-self: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 30vw;
      height: 30vw;
      max-height: 536px;
      max-width: 536px;
    }
    img {
      margin: auto;
      width: auto;
      max-height: 536px;
      margin-right: 0;
    }
  }

  @media screen and (max-width: ${breakpoints[1200]}) {
    & > div {
      grid-column: span 8;
    }

    .img {
      width: 20vw;
      height: 20vw;

      div {
        width: 20vw;
        height: 20vw;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(8, 1fr);
    padding: var(--gap-45) 0;
    margin-bottom: var(--gap-83);

    & > div {
      grid-column: 1 / -1;
      order: 1;
    }

    .img {
      grid-column: 1 / -1;
      order: 0;
      width: 30vw;
      height: 30vw;

      div {
        width: 30vw;
        height: 30vw;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.small}) {
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: var(--gap-93);

    .img {
      grid-column: 1 / -1;
      order: 0;
      width: 50vw;
      height: 50vw;

      div {
        width: 50vw;
        height: 50vw;
      }
    }
  }
`

export const Container = styled.div`
  display: contents;
  width: 100%;

  section {
    grid-column: 1 / -1;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
  }
`

export const Cooperation = styled.section`
  display: contents;

  & > h3 {
    grid-column: 1 / -1;
    font-size: var(--font-size-desktop-h1);
    margin-bottom: var(--gap-50);
    justify-self: start !important;
  }

  & :nth-child(odd) {
    justify-self: end;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    & > h3 {
      margin-bottom: var(--gap-38);
      font-size: var(--font-size-tablet-h1) !important;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    & > h3 {
      margin-bottom: var(--gap-41);
      font-size: var(--font-size-mobile-h1) !important;
    }
  }
`

export const Network = styled.section`
  display: contents;

  & > h3 {
    grid-column: 1 / -1;
    font-size: var(--font-size-desktop-h1);
    margin-top: calc(var(--gap-165) - var(--gap-66));
    margin-bottom: var(--gap-50);
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    & > h3 {
      margin-top: calc(var(--gap-83) - var(--gap-45));
      margin-bottom: 0;
      font-size: var(--font-size-tablet-h1) !important;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    & > h3 {
      margin-bottom: 0;
      font-size: var(--font-size-mobile-h1) !important;
    }
  }
`

export const Intro = styled.section`
  & > h1 {
    grid-column: 1 / -1;
    font-size: 2.75em;
  }

  & > h3 {
    grid-column: 1 / 7;
    max-width: 800px;
  }

  & > h1 {
    margin-bottom: var(--gap-50);
  }

  & > h3 {
    margin-bottom: var(--gap-165);
  }

  @media screen and (max-width: ${breakpoints["1200"]}) {
    & > h1,
    & > h3 {
      grid-column: 1/ -1;
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    & > h1 {
      margin-bottom: var(--gap-38);
    }

    & > h3 {
      margin-bottom: var(--gap-83);
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    & > h1 {
      margin-bottom: var(--gap-41);
    }

    & > h3 {
      margin-bottom: var(--gap-45);
    }
  }
`
