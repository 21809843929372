import React, { useState } from "react"

import Arrow from "@assets/slide/arrow.svg"

import * as Styled from "./Slide.styles"
import createKey from "../../../../services/key"
import { IGatsbyImageData } from "gatsby-plugin-image"

interface Props {
  data: {
    text: any
    author: string
    job: string
    blockquoteActive: boolean
    blockquote?: string
  }[]
}

const Slide = ({ data }: Props) => {
  const [current, setCurrent] = useState(0)

  return (
    <Styled.Container>
      <button
        onClick={() =>
          setCurrent(
            current === 0 ? data.length - 1 : (current - 1) % data.length
          )
        }
        className="button"
      >
        <Arrow />
      </button>
      <Styled.Content blockquoteActive={data[current].blockquoteActive}>
        <blockquote>"{data[current].blockquote}"</blockquote>
        {data[current].text}
        <p className="author">{data[current].author}</p>
        <p className="job">{data[current].job}</p>
      </Styled.Content>
      <button
        onClick={() => setCurrent((current + 1) % data.length)}
        className="button"
      >
        <Arrow />
      </button>
      {/* <Styled.Points blockquoteActive={data[current].blockquoteActive}>
        {data.map((_, i) => (
          <Styled.SlideButton
            type="button"
            active={i === current}
            onClick={() => setCurrent(i)}
            key={createKey()}
          />
        ))}
      </Styled.Points> */}
      <Styled.ButtonContainer>
        <button
          onClick={() =>
            setCurrent(
              current === 0 ? data.length - 1 : (current - 1) % data.length
            )
          }
        >
          <Arrow />
        </button>
        <button onClick={() => setCurrent((current + 1) % data.length)}>
          <Arrow />
        </button>
      </Styled.ButtonContainer>
      <Styled.Points blockquoteActive={false}>
        <p>
          {current + 1}/{data.length}
        </p>
      </Styled.Points>
    </Styled.Container>
  )
}

export default Slide
