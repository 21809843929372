import styled from "styled-components"
import breakpoints from "../../../../configs/breakpoints"

export const ImgWrapper = styled.div`
  width: 100vw;
  min-width: 285px;
  max-width: 385px;
  margin-right: 5%;
  align-self: center;
  justify-self: center;

  img {
    width: 100%;
    height: auto;
  }
`

export const Container = styled.section`
  display: flex;
  grid-column: 2/-2;
  margin-bottom: var(--gap-66);

  h4 {
    margin-top: var(--gap-27);
  }

  li {
    margin-bottom: var(--gap-50);

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > div {
    display: flex;
    width: 100%;
    flex-direction: column;

    h4,
    & > div p {
      text-align: center;
    }

    & > div p {
      margin-bottom: var(--gap-27);
    }
  }

  @media screen and (max-width: ${breakpoints[1200]}) {
    grid-column: 1 / -1;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    ${ImgWrapper} {
      min-width: unset;
      max-width: 138px;
    }

    h4 {
      margin-top: unset;
    }

    div div p {
      margin-bottom: var(--gap-13);
    }

    li {
      margin-bottom: var(--gap-38);
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${ImgWrapper} {
      max-width: 200px;
      margin-right: 0;
    }

    .break:first-child:after {
      content: "-";
    }

    h4 {
      margin-top: var(--gap-13);
    }

    div div p {
      margin-bottom: var(--gap-41);
    }
  }
`
