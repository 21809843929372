import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import createKey from "@services/key"

import * as Styled from "./Partner.styles"

interface Props {
  img?: any
  imgAlt?: string
  name: string
  job: string | any
  data: { title: string | any; text: string | any }[]
}

const Partner = (props: Props) => {
  const image: any = getImage(props.img)

  return (
    <Styled.Wrapper>
      <Styled.ImgContainer>
        <Styled.ImgWrapper>
          {image && props.imgAlt ? (
            <GatsbyImage alt={props.imgAlt} image={image} objectFit="contain" />
          ) : (
            <div className="placeholder" />
          )}
        </Styled.ImgWrapper>
        <div className="desc">
          <p>{props.name}</p>
          <>{props.job}</>
        </div>
      </Styled.ImgContainer>
      <Styled.Ul>
        {props.data.map(obj => (
          <li key={createKey()}>
            <h3 key={createKey()}>{obj.title}</h3>
            <p key={createKey()}>{obj.text}</p>
          </li>
        ))}
      </Styled.Ul>
    </Styled.Wrapper>
  )
}

export default Partner
