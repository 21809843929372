import styled from "styled-components"
import breakpoints from "@configs/breakpoints"

export const ImgContainer = styled.div`
  display: flex;
  background: var(--clr-green);
  align-items: center;
  justify-content: center;
  min-height: 280px;

  & p:first-child {
    margin-bottom: var(--gap-27);
  }

  & div:last-child {
    padding: var(--gap-18);

    p:first-child {
      font-weight: bold;
      font-size: 1.5em;
    }
  }

  & > div:nth-child(2) {
    justify-self: start;
    margin-right: auto;
  }

  .placeholder {
    height: 100vw;
    max-height: 250px;
    width: auto;
    background: grey;
  }
`

export const ImgWrapper = styled.div`
  min-width: 225px;
  height: auto;

  img {
    width: 100%;
    height: 106%;
  }
`

export const Ul = styled.ul`
  border: 1px solid var(--clr-green);
  padding: var(--gap-66) var(--gap-45);
  min-height: 500px;

  li {
    margin-bottom: var(--gap-50);

    &:last-child {
      margin-bottom: 0;
    }

    & :first-child {
      margin-bottom: var(--gap-13);
    }
  }

  @media screen and (max-width: ${breakpoints["1200"]}) {
    min-height: unset;
  }

  @media screen and (max-width: 429px) {
    padding: var(--gap-18);
    h3 span {
      &:first-child:after {
        content: "-";
      }
    }

    .break {
      &:after {
        content: "-";
      }
    }
  }
`

export const Wrapper = styled.div`
  grid-column: span 6;
  margin-bottom: var(--gap-66);
  width: 100%;
  max-width: 730px;

  @media screen and (max-width: ${breakpoints.desktop}) {
    max-width: 600px;
  }

  @media screen and (max-width: ${breakpoints[1200]}) {
    grid-column: 1 / -1;
    max-width: unset;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin-bottom: var(--gap-93);

    ${ImgWrapper} {
      max-height: 280px;

      img {
        max-height: 280px;
        margin: 0 auto;
      }

      .placeholder {
        max-height: 280px;
      }
    }

    ${ImgContainer} {
      flex-direction: column;
      padding-top: var(--gap-18);

      & p:first-child {
        margin-bottom: var(--gap-13);
      }
    }
  }
`
