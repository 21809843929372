import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import createKey from "../../../../services/key"

// styles
import * as Styled from "./Profil.styles"

interface Props {
  img: IGatsbyImageData
  imgAlt: string
  name: string
  job: string
  p: string[] | any
  kredo?: boolean
}

const Profil = ({ name, job, p, img, imgAlt, kredo }: Props) => {
  const image: any = getImage(img)
  const titles = [
    "Über",
    "Ausbildung",
    "Schwerpunktthemen",
    "Meine Zentralen Anliegen",
    "Kredo",
  ]

  return (
    <Styled.Container>
      <Styled.ImgWrapper>
        <GatsbyImage alt={imgAlt} image={image} />
      </Styled.ImgWrapper>
      <div>
        <div>
          <h4>{name}</h4>
          <p>{job}</p>
        </div>
        <ul>
          {p.map((v: any, i: any) => {
            if (i === 0)
              return (
                <li key={createKey()}>
                  <h3 key={createKey()}>Über {name}</h3>
                  <p key={createKey()}>{v}</p>
                </li>
              )
            else if (i === 3 && kredo) {
              return (
                <li key={createKey()}>
                  <h3 key={createKey()}>{titles[4]}</h3>
                  <p key={createKey()}>{v}</p>
                </li>
              )
            } else
              return (
                <li key={createKey()}>
                  <h3 key={createKey()}>{titles[i]}</h3>
                  <p key={createKey()}>{v}</p>
                </li>
              )
          })}
        </ul>
      </div>
    </Styled.Container>
  )
}

export default Profil
