import React from "react"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

import * as Styled from "./Network.styles"
import createKey from "@services/key"

interface Props {
  img: IGatsbyImageData
  imgAlt: string
  name: string
  text: string[]
}

const Network = (props: Props) => {
  const image: any = getImage(props.img)
  return (
    <Styled.Container>
      <Styled.ImgContainer>
        <GatsbyImage alt={props.imgAlt} image={image} objectFit="contain" />
      </Styled.ImgContainer>
      <Styled.Text>
        <div>
          <p>{props.name}</p>
          {props.text.map(str => (
            <p key={createKey()}>{str}</p>
          ))}
        </div>
      </Styled.Text>
    </Styled.Container>
  )
}

export default Network
