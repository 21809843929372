import styled from "styled-components"
import breakpoints from "@configs/breakpoints"

export const ImgContainer = styled.div`
  width: 100vw;
  height: 100vw;
  max-height: 220px;
  max-width: 275px;
  grid-column: span 3;
  align-self: center;
  justify-self: center;

  img {
    width: auto;
    height: 100%;
    max-height: 220px;
    min-width: 275px;
  }
`

export const Text = styled.div`
  grid-column: span 3;
  background: var(--clr-green);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px;

  & > div p:first-child {
    margin-bottom: var(--gap-13);
    font-weight: bold;
  }
`

export const Container = styled.div`
  display: contents;

  @media screen and (max-width: ${breakpoints[1200]}) {
    ${ImgContainer}, ${Text} {
      grid-column: span 6;
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    ${ImgContainer}, ${Text} {
      grid-column: 1 / -1;
    }

    ${ImgContainer} {
      margin-bottom: var(--gap-13);
      margin-top: var(--gap-43);
    }
  }
`
