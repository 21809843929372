import React from "react"

// components
import Layout from "@modules/layout"
import Seo from "@modules/seo"
import Team from "@modules/team"

const IndexPage = ({ location }: any) => (
  <Layout location={location}>
    <Seo title="Home">
      <Team />
    </Seo>
  </Layout>
)

export default IndexPage
