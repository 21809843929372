import styled, { css } from "styled-components"
import breakpoints from "@configs/breakpoints"

export const Content = styled.div<{ blockquoteActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: span 10;
  padding: var(--gap-18) 10px;
  border-radius: 5px;
  min-height: 368px;
  margin: 0 var(--gap-27);

  & > blockquote {
    display: none;
  }

  & .author {
    font-weight: bold;
    text-align: center;
    margin-top: var(--gap-50);
  }

  & .job {
    text-align: center;
  }

  ${props =>
    props.blockquoteActive
      ? css`
          & > blockquote {
            display: block;
            margin-bottom: var(--gap-27);
            font-weight: bold;
          }
        `
      : ""}
`

export const Points = styled.div<{ blockquoteActive: boolean }>`
  display: flex;
  justify-content: center;
  grid-column: 1 / -1;
  margin-top: var(--gap-27);
  ${props =>
    !props.blockquoteActive
      ? css`
          margin-top: 0;
        `
      : ""}

  font-weight: bold;
`

export const SlideButton = styled.button<{ active: boolean }>`
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  margin-right: var(--gap-13);

  &:last-child {
    margin-right: 0;
  }

  ${props =>
    !props.active
      ? css`
          opacity: 0.5;
        `
      : ""}
`

export const ButtonContainer = styled.div`
  display: none;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;

  & button:last-child {
    transform: rotate(180deg);
    margin-top: -10px;
  }

  & button:first-child {
    margin-right: 50px;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    display: flex;
    visibility: visible;
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--grid-gap-16);
  width: 100%;

  button {
    cursor: pointer;
  }

  & button:first-child {
    justify-self: end;
  }

  & button:nth-child(3) {
    justify-self: start;
    transform: rotate(180deg);
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(8, 1fr);

    ${Content} {
      position: relative;
      grid-column: 1 / -1;
      margin: 0;
      overflow-wrap: break-word;

      & p:first-child {
        margin-bottom: var(--gap-38);
      }
    }

    & > button:first-child,
    & > button:nth-child(3) {
      position: absolute;
      top: 15%;
      bottom: 0;
    }

    & > button:first-child {
      left: calc(var(--gap-13) * -2.5);
    }

    & > button:nth-child(3) {
      right: calc(var(--gap-13) * -2.5);
    }

    ${Points} {
      margin-top: var(--gap-13);
    }
  }

  @media screen and (max-width: ${breakpoints[1200]}) {
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    grid-template-columns: repeat(4, 1fr);
    .button {
      display: none;
      visibility: hidden;
    }

    ${Content} {
      grid-column: 1 / -1;
      margin: 0;
      overflow-wrap: break-word;

      & p:first-child {
        margin-bottom: var(--gap-41);
      }
    }
  }
`
